<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-content>
      <v-list-item-title
        class="text-wrap"
      >
        {{ formattedItem.title }}
      </v-list-item-title>
      <v-list-item-subtitle
        class="text-wrap"
      >
        {{ formattedItem.subtitle }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <b10-list-item-right-avatar
      v-if="formattedItem.badge"
      :color="formattedItem.badgeColor"
    >
      {{ formattedItem.badge }} {{ 'unidad'|pluralize(formattedItem.badge, 'es') }}
    </b10-list-item-right-avatar>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    hasPermBorrar: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
    }
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = item.descripcion
      item.subtitle = item.codigo
      item.badge = item.cantidad_material
      return item
    }
  },
  methods: {
    clickDelete () {
      this.$emit('click-delete', { item: this.item, index: this.index })
    },
  },
}
</script>
